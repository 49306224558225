import * as React from "react";
import { UitkRadioButtonGroup } from "@egds/react-core/radio-button";
import { observer } from "mobx-react";
import { Radio } from "../components/Radio";
import { withStores } from "src/stores";
import { PropertyFiltersComponentGroupProps } from "../typings";
import { useClickTracker } from "@shared-ui/clickstream-analytics-context";

export const RadioButtonGroup = withStores("propertyFilters")(
  observer((props: PropertyFiltersComponentGroupProps) => {
    const { filterLabel, filterName, options, propertyFilters, moduleName } = props;

    const track = useClickTracker();

    const onChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
      const value = event.target.value;
      const isSelected = propertyFilters.isSelected(filterName, value ?? "");
      const rfrr = `${moduleName}.${moduleName}.${filterName}.${value}.${!isSelected}.click`;
      const linkName = "RFRR Action Link";
      track(rfrr, linkName);

      propertyFilters.toggleSelection(filterName, value ?? "");
    };

    return (
      <UitkRadioButtonGroup legendLabel={filterLabel} group={filterName} onChange={onChange}>
        {options.map((option, index) => (
          <Radio
            key={`${option.optionValue}-${index}`}
            option={option}
            filterName={filterName}
            moduleName={moduleName}
          />
        ))}
      </UitkRadioButtonGroup>
    );
  })
);

export default RadioButtonGroup;
